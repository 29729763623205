import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SDepartmentsService {

  arrActivity: any = []
  arrActivity_filter: any = []
  
  acad_year:any = '2024-2025'

  constructor(private http: HttpClient) { }

  getData(dept_id) {
    this.http.get('https://api.prpotepatilengg.ac.in/api/pharmaActvities/Getpharma_activities_by_dept?dept_id=' + dept_id)
      .subscribe(
        (res:any) => {
          this.arrActivity = res
          this.arrActivity_filter = res.filter(s=>s.sub_title_year == this.acad_year)
        }
      )
  }
}
